import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { homeSlidersdata } from './Sliderdata';

const handleDragStart = (e) => e.preventDefault();

const items =homeSlidersdata.map((item)=>
    <img src={item.image} className='cursor-pointer' alt="" role="presentation" />
);

// const items = [
//   <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
//   <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
//   <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
// ];

const Slider = () => {
  return (
    <AliceCarousel  items={items}
     disableButtonsControls
     autoPlay
     autoPlayInterval={1000}
     infinite />
  );
}

export default Slider;