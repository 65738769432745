import { useState,useEffect } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { RadioGroup } from '@headlessui/react'
import { Button, Grid, Rating } from '@mui/material';
import { useParams } from 'react-router-dom';
import SeoSection from '../SchoolView/SeoSection';
import PhotoSection from '../SchoolView/PhotoSection';
import SingleViewSection from '../SchoolView/SingleViewSection';

import GetAdmissionknowSection from '../../Components/CommonSection/GetAdmissionknowSection';
import PopularLocalitiesSection from '../../Components/CommonSection/PopularLocalitiesSection';
import CommonEnquirySection from '../../Components/CommonSection/CommonEnquirySection';
import AdmissionEnquirySection from '../../Components/CommonSection/AdmissionEnquirySection';
import CommonSchoolSection from '../../Components/CommonSection/CommonSchoolSection';

import EnquirySection from '../RightSidebar/EnquirySection';
import ContactSection from '../RightSidebar/ContactSection';
import AddressSection from '../RightSidebar/AddressSection';
import Banner from '../Assets/Banner.jpg';


import ProductReviewCard from './ProductReviewCard'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import FsLightbox from "fslightbox-react";


export default function ProductDetails() {
 
  const [toggler, setToggler] = useState(false);
  var sectionStyle = {
    backgroundImage: `url(${Banner})`
  }
  // const location=useLocation();
  // const searchParamms=new URLSearchParams(location.search)
  // let filterValue=searchParamms.get('schoolslug');
  

  const { schoolslug } = useParams();
  console.log(schoolslug);

  const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getSchool_view_data/${schoolslug}`;
  const [schooln,setschooln]= useState([]);
  const [schoolPhoto,setSchoolPhoto]=useState([]);


useEffect(()=>{
  window.scrollTo(0, 0);
  //document.title="School in West Bengel - School Root Education";
  async function singleschoolData() {
    
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({
      //   slugurl:'kids_kinetics_school',
      // }),
    }
    const res = await  fetch(VISITOR_URL, requestOptions)
    .then((res) => res.json())
    .then((data) => {
       console.log('test'+JSON.stringify(data));
       console.log('test2'+JSON.stringify(data.data));
       if(data.status==='Success')
       {
        setschooln(data.data);
        if(data.photodata.data)
        {
        setSchoolPhoto(data.photodata.data);
        }
        else
        {
          setSchoolPhoto([]);
        }
       }
       else
       {
        setschooln();
       }
    }).catch(rejected => {
      console.log(rejected);
  });

  }
  singleschoolData();
 
},[setschooln]);


  return (
    <div className="bg-white">
       
       { schooln.length?schooln.map((data,i)=>(<SeoSection data={data}/>)):'null'}
      
      <Header/>
      <div>
      { schooln.map((data,i)=>( 
       
       <div class="container-fluid" style={sectionStyle}>  
      <div class="container">
      
  <div class="mt-10 pt-5 pb-5 text-white shadow mb-5 row">
    <h1>{data.fld_school_name}({data.fld_school_short_name}), {data.fld_city}, {data.fld_state}</h1> 
    <h6>{data.fld_address}, {data.fld_city}, {data.fld_state}- {data.fld_pincode}</h6> 

  </div>
</div>
     </div>

 
 ))}
       { schooln.map((data,i)=>( 
        

        <div className="container p-6">
      <div className="row">
        <div class="col-md-8 col-sm-8">
        
        <SingleViewSection data={data}/>


        <div class="col-md-12 col-sm-12 card mb-5">
        <h5 class="card-header callus_bg">Photo Gallery </h5>
        <div class="card-body">

          <div className="row">
          { schoolPhoto.length > 0 ?<button onClick={() => setToggler(!toggler)}>View All Photo</button>:''}
			 
          {/* { schoolPhoto.length > 0? schoolPhoto.map((data,i)=>(<PhotoSection data={data}/>)):''} */}
          { schoolPhoto.length > 0? schoolPhoto.map((data,i)=>(<FsLightbox
				toggler={toggler}
				sources={[`${process.env.REACT_APP_IMAGE_URL}${data.fld_photo_url}`]}
			/>)):''}
       
         

         
        </div>
        </div>
       </div>

       
      </div>



        <div class="col-md-4 col-sm-4">
        <ContactSection />
        <AddressSection />
        <EnquirySection />

        



        </div>
      </div>
      </div>









        ))} </div>

     <GetAdmissionknowSection />
     <PopularLocalitiesSection/>
     <CommonEnquirySection/>
     <AdmissionEnquirySection/>
     <CommonSchoolSection/>
          
      <Footer/>
    </div>


  )
}
