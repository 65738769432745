import React from 'react';
import { Helmet } from 'react-helmet';


function SeoSection({data}) {

  const IMAGEURL= `${process.env.REACT_APP_IMAGE_URL}${data.fld_school_banner}`;
  const SCHOOLURL=`/school/${data.fld_url}`;
  //const newImageurl=`${IMAGEURL+product.fld_school_banner}`;
  return (
    <>
    <Helmet>
    <title>{data.fld_meta_title}</title>
    <meta name="description" content={data.fld_meta_description} />
    <meta name="keywords" content={data.fld_meta_keywords}/>
    <meta name="author" content="TSchoolRoot Eduaction" />
    <meta property="og:title" content={data.fld_meta_title} />
    <meta property="og:description" content={data.fld_meta_description} />
    <meta property="og:image" content={IMAGEURL} />
    <meta property="og:url" content={SCHOOLURL}/>
    <meta name="twitter:title" content={data.fld_meta_title} />
    <meta name="twitter:description" content={data.fld_meta_description} />
    <meta name="twitter:image" content={IMAGEURL} />
    <meta name="twitter:card" content={IMAGEURL} />
    </Helmet>
    </>
  )
}

export default SeoSection