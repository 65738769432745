import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Header from '../../Admin/Header/Header';
import React, { useState,useRef,useEffect } from 'react';
import { useNavigate,Link,useParams } from 'react-router-dom';
import LeftNav from '../../Admin/LeftNav/LeftNav';
import DefaultImage from "../../Assets/School_eduation_logo.jpg";
import EditIcon from "../../Assets/edit.svg";
import UploadingAnimation from "../../Assets/uploading.gif";
import DataTable from "react-data-table-component";

export default function Post() {


  const createUserApi = `${process.env.REACT_APP_API_URL}auth/saveSchoolPhoto`;
  const getSchoolPhotoApi = `${process.env.REACT_APP_API_URL}auth/getSchoolPhotoData`;
  const delete_visitor_booking_URL = `${process.env.REACT_APP_API_URL}auth/deleteData`;

  const navigate = useNavigate();
  const { id } = useParams();
  console.log('school',id)

// local
  const [avatarURL, setAvatarURL] = useState(DefaultImage);
  const [selCategory, setCategory] = useState("");
  const [selSchool, setSchool] = useState(id);

  const [getSchoolPhoto,setSchoolPhoto]=useState([]);

  const fileUploadRef = useRef();

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  }

  const uploadImageDisplay = async () => {
    try {
      setAvatarURL(UploadingAnimation);
      const uploadedFile = fileUploadRef.current.files[0];
      // const cachedURL = URL.createObjectURL(uploadedFile);
      // setAvatarURL(cachedURL);
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("selcategory", selCategory);
      formData.append("selschool", selSchool);
       //  'Content-Type': 'application/json',
      const response = await fetch(createUserApi, {
        method: "POST",
        headers: {
          'Authorization': localStorage.getItem("accessToken")
          },
        body: formData
      });
      console.log('response'+response);
      if (response.status === 200) {
        const data = await response.json();
          console.log(data);
        if(data.status=='Success')
          {
           alert("Photo Upload Successfully");
           navigate('/SchoolDetails');
          }
       // setAvatarURL(data?.location);
      }
    } catch(error) {
      console.error(error);
      setAvatarURL(DefaultImage);
    }
  }

//delete data


async function deleteStudent(id) {
  const pdata={
    id:id,
    vtype:'schoolphotodelete'
  };
  const response = await fetch(delete_visitor_booking_URL, {
    method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': localStorage.getItem("accessToken"),
                  },
                  body: JSON.stringify(pdata),
  });
  return response.json();
}



// show school photo

useEffect(()=>{
  
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      'Authorization': localStorage.getItem("accessToken"),
     },
      body: JSON.stringify({
        schoolid:selSchool,
      })
    }

  fetch(getSchoolPhotoApi, requestOptions)
    .then((response) => response.json())
    .then((data) => {
       console.log(data.data);
       if(data.status==='Success')
       {
        setSchoolPhoto(data.data);
     
       }
       else
       {
        setSchoolPhoto();
       }
    });
 
},[getSchoolPhoto]);


const columns = [
  {
      name: 'ID',
      selector: (row) => row.fld_id,
      sortable: true,
  },
  {
      name: 'Photo Type',
      selector: (row) => row.fld_photo_type,
      sortable: true,
  },
  
  {
      name: 'Photo Url',
      selector: (row) => row.fld_photo_url,
      sortable: true,
  },
{
name: 'Created On',
selector: (row) => row.fld_created_on,
sortable: true,
},
{
name:"Action",
cell: (row) => (
    <>
  <span onClick={() => handleButtonClick(row.fld_id)} className='btn btn-sm btn-primary'>Delete</span>{'     '}
  </>
),
ignoreRowClick: true,
allowOverflow: true,
button: true,
}


];

const rows=getSchoolPhoto;

const [data, setData] = useState(rows); 
// Handle Search
const handleButtonClick = (id) => {
  // e.preventDefault();
  deleteStudent(id)
  alert('Data Delete Successfully');
  console.log("Row Id", id);
 };




  return (
    <>
    <Header/>
    <div className="container-fluid">
    <div className="row">
    <LeftNav/>

    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Photo Gallery</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <Link to="/SchoolDetails" class="btn btn-sm btn-outline-secondary">Back</Link>
          </div>
          
        </div>
      </div>
     

      <img 
        src={avatarURL}
        alt ="Avatar"
        className="h-96 w-96 rounded-full" width={150} />
     
      <form id="form" encType='multipart/form-data'>
      <div className="container">
        <div className="row">

        <div className="col-md-6">
              <label hmlFor="schoolcategory" className="form-label">Photo Category </label>    
                  <select
                    id="selCategory"
                    name="selCategory"
                    type="text"
                    value={selCategory}  
                    onChange={(e) => setCategory(e.target.value)}
                    placeholder="Select Category"
                    className="form-select">
                   <option value="">Select Category</option>
                   <option>Primary</option>
                   <option>Front</option>
                   <option>Ground</option>
                   <option>ClassRoom</option>
                   <option>Logo</option>
                   <option>Others</option>
                    </select>

                    <input
                    id="schoolid"
                    name="schoolid"
                    type="text"
                    value={selSchool}  
                    onChange={(e) => setSchool(e.target.value)}
                    className="form-control"
                    hidden
                  />
                
            </div>

            
          
          <div className="col-md-8 mt-4">
              <label hmlFor="schoolphoto" className="form-label">Upload Photo </label>           
           <input 
          type="file"
          id="file"
          className="form-control"
          ref={fileUploadRef}
          onChange={uploadImageDisplay}/>
            </div>


      {/* <div className="col-md-6 mt-8">
        <button type="submit"  onClick={handleImageUpload}  className="btn bg-primary"> Save </button>
      </div> */}

                
    </div>
    </div>
    </form>
    <div class="col-md-12">
    <DataTable 
           columns={columns} 
            data={rows} 
            fixedHeader
            title="School Photo Lists"
            pagination
           selectableRows
         />
    </div>
    </main>
    </div>
    </div>
   </>
  )
}