import { Button, Grid, Typography } from '@mui/material'
import React from 'react';
import {Link} from 'react-router-dom';

const PopularLocalitiesDataSetion = ({data}) => {
  const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
     <div className="col-sm-4 col-md-4 mb-4">
      <Link to={SCHOOLURL}>
      <div className="setheaderbg p-4 shadow  rounded">
      {data.cityname}
      </div>
      </Link>
     </div> 
    </>
  )
}

export default PopularLocalitiesDataSetion