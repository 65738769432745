import React from 'react';
import { Helmet } from 'react-helmet';


function SeoSectionPage({data}) {

  const SCHOOLURL=`/school/${data.seourl}`;
  const CanonicalURL=`/west-bengal/${data.seourl}`;
  //const newImageurl=`${IMAGEURL+product.fld_school_banner}`;
  return (
    <>
    <Helmet>
    <title>{data.meta_title}</title>
    <meta name="description" content={data.meta_description} />
    <meta name="keywords" content={data.meta_keywords}/>
    <meta name="author" content="TheSchool Root Eduaction" />
    <meta property="og:title" content={data.meta_title} />
    <meta property="og:description" content={data.meta_description} />
    <meta name="twitter:title" content={data.meta_title} />
    <meta name="twitter:description" content={data.meta_description} />
    <link rel="canonical" href={CanonicalURL} />
    </Helmet>
    </>
  )
}

export default SeoSectionPage