import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import Header from './Components/Header/Header';
import Home from './Components/Pages/Home';
import Footer from './Components/Footer/Footer';
import Product from './Components/Product/Product';
import Protected from './Components/Protected';
import ProductDetails from './Components/ProductDetails/ProductDetails';
import Login from './Components/Login/Login';
import Post from'./Components/Admin/Post/Post';
import SchoolEnquiry from'./Components/Admin/Enquiry/ViewEnquiry';
import SchoolPhoto from'./Components/Admin/Post/SchoolPhotoSection';
import AddPost from'./Components/Admin/Post/PostDetails';
import Category from'./Components/Admin/CategoryMaster/Category';
import About from'./Components/Pages/About';
import Contact from'./Components/Pages/Contact';
import SchoolsExplore from './Components/Pages/SchoolView';
import WestBengalSection from './Components/Pages/WestBengalSection';
import AdmissionEnquiry from'./Components/Pages/AdmissionEnquiry';
import CityMaster from'./Components/Admin/CityMaster/ViewCities';
import LocalityMaster from'./Components/Admin/LocalityMaster/ViewLocality';

function App() {
  return (
  <>
  <BrowserRouter>
  
      <Routes>
        <Route
          exact
          path="/"
          element={<Home />}
        ></Route>

        
        <Route
          exact
          path="/about-us"
          element={<About />}
        ></Route>

       
        <Route
          exact
          path="/contact-us"
          element={<Contact />}
        ></Route>


<Route
          
          path="/explore-top-schools/:schoolslug?/:pathParam2?"
          element={<SchoolsExplore />}
        ></Route>

<Route
          exact
          path="/admission-enquiry"
          element={<AdmissionEnquiry />}
        ></Route>



        <Route
          exact
          path="/Product"
          element={<Product />}
        ></Route>


        <Route
          exact
          path="/Category"
          element={<Category />}>
          </Route>

         <Route
          exact
          path="/ProductDetails"
          element={<ProductDetails />}
        ></Route>

          <Route
          path="/school/:schoolslug"
          element={<ProductDetails />}
        ></Route>


         <Route
          path="/west-bengal/:pageslug"
          element={<WestBengalSection />}
        ></Route>

         
         <Route
          exact
          path="/Login"
          element={<Login />}
        ></Route>
         
        <Route
          exact
          path="/SchoolDetails" element={
            <>
              <Protected Cmp={Post} />
            </>}>
        </Route>

        <Route
          exact
          path="/AddSchool" element={
            <>
              <Protected Cmp={AddPost} />
            </>}>
            </Route> 


            <Route
          path="/SchoolPhoto/:id" element={
            <>
              <Protected Cmp={SchoolPhoto} />
            </>}>
            </Route> 


            <Route 
            exact
             path="/SchoolEnquiry" element={
            <>
              <Protected Cmp={SchoolEnquiry} />
            </>}>
            </Route> 


            <Route 
            exact
             path="/CityMaster" element={
            <>
              <Protected Cmp={CityMaster} />
            </>}>
            </Route> 

            <Route 
            exact
             path="/LocalityMaster" element={
            <>
              <Protected Cmp={LocalityMaster} />
            </>}>
            </Route> 


            

      
      </Routes>

  </BrowserRouter>
  
  </>
);
}

export default App;
