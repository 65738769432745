import React, { useState,useEffect } from 'react';
import Header from '../../Admin/Header/Header';
import { useNavigate,Link } from 'react-router-dom';
import DataTable from "react-data-table-component";
import LeftNav from '../../Admin/LeftNav/LeftNav';
import axios from 'axios';
//import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';


  function Post() 
  {

  const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getSchoolData`;
  const delete_visitor_booking_URL = `${process.env.REACT_APP_API_URL}auth/deleteData`;

 const navigate = useNavigate();
 // const tableRef = useRef(null);
 const [visitorn,setvisitorn]= useState([]);

 async function deleteStudent(id) {
   const pdata={
     id:id,
     vtype:'schooldelete'
   };
   const response = await fetch(delete_visitor_booking_URL, {
     method: 'POST',
                   headers: {
                       'Content-Type': 'application/json',
                       'Authorization': localStorage.getItem("accessToken"),
                   },
                   body: JSON.stringify(pdata),
   });
   return response.json();
 }

 const deleteData = async id => {
   await deleteStudent(id);
   alert('Data delete successfully');
 };

 

 useEffect(()=>{
  document.title="School Details - School Root Education";

  // const requestOptions = {
  //   method: "POST",
  //   headers: {
  //             'Content-Type': 'application/json',
  //             //'Authorization': localStorage.getItem("accessToken"),
  //             },
    
  // };


  const headers= {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem("accessToken"),
              };
    

  axios.post(VISITOR_URL,{headers}).then(res=>setvisitorn(res.data['data'])).catch(err=>console.log(err))}

  // axios.post(VISITOR_URL, requestOptions)
    
  //   .then((data) => {
  //      console.log(data.data);
  //      if(data.status==='Success')
  //      {
  //       setvisitorn(data.data);
     
  //      }
  //      else
  //      {
  //       setvisitorn();
  //      }
  //   });
 


,[]);
 


const handleUploadClick = (id) => {
  // e.preventDefault();
  navigate(`/SchoolPhoto/${id}`);
  // console.log("Row Id", id);
 };
  const columns = [
    {
      name:"Action",
      width: "190px",   
      cell: (row) => (
          <>
        <span onClick={() => deleteData(row.fld_id)} className='btn btn-sm btn-primary'>Delete</span>{'     '}
         &nbsp; &nbsp;<span onClick={() => handleUploadClick(row.fld_id)} className='btn btn-sm btn-danger'>Add Photo</span>
        </>
      ),
      ignoreRowClick: true,
      button: true,
    },
    {
        name: 'ID',
        width: "80px",
        selector: (row) => row.fld_id,
        sortable: true,
    },
    {
        name: 'School Name',
        selector: (row) => row.fld_school_name,
        sortable: true,
    },
    
    {
        name: 'School estd',
        width: "120px",
        selector: (row) => row.fld_school_estd,
        sortable: true,
    },
    {
      name: 'Classes',
      width: "180px",
      selector: (row) => row.fld_school_classes,
      sortable: true,
  },
  {
    name: 'Board',
    width: "80px",
    selector: (row) => row.fld_school_board,
    sortable: true,
},
{
  name: 'Address',
  selector: (row) => row.fld_address,
  sortable: true,
},
{
  name: 'Created On',
  selector: (row) => row.fld_created_on,
  sortable: true,
},


  ];
  
  const rows=visitorn;
  
  const [data, setData] = useState(rows); 
  // Handle Search


  return (
    <div>
    <Header/>
    <div class="container-fluid">
    <div class="row">
    <LeftNav/>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">School Details</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            {/* <button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" class="btn btn-sm btn-outline-secondary">Export</button> */}
            <Link to="/AddSchool" class="btn btn-sm btn-outline-primary">Add New School</Link>
          </div>
          {/* <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button> */}
        </div>
      </div>
      <input type="search" className="form-control-sm border ps-3" placeholder="Search" />
       
      <DataTable 
           columns={columns} 
            data={rows} 
            fixedHeader
            title="View School Details"
            pagination
           selectableRows
         />
      
    </main>
  </div>
</div>
</div>
  )
  }

export default Post;