import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import GridSection from '../../Components/SchoolView/GridSection';

function GridSectionStatic({datan}) {


const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getSchoolpublicdata`;
const [schooldata,setschooldata]= useState({});
const [Slugdata,setSlugdata]= useState(datan.cityname);

async function fetchData()
{
  setSlugdata(datan.seourl);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cityname:datan.cityname,
      })
    }

      
  const response=await fetch(VISITOR_URL, requestOptions)
    .then((response) => response.json())
    .then((data) => {
       console.log(data);
       if(data.status==='Success')
       {
        setschooldata(data.data);
       }
       else
       {
        setschooldata([]);
       }
    })
    .catch(rejected => {
      console.log(rejected);
  });
    
}


useEffect(()=>{ 
  fetchData();
},[datan.cityname]);

  return (
    <>
       {schooldata.length > 0 ? schooldata.slice(0,6).map((datan,i)=>( <GridSection data={datan} />)) : null}

    </>
  )
}

export default GridSectionStatic