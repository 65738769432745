import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Logonew from "../../Assets/School_eduation_logo.jpg";
import { Link,useNavigate } from 'react-router-dom';

const user = {
  name: 'Admin',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}


export default function LeftNav() {

  const navigate = useNavigate();
  const selectrole=localStorage.getItem("userrole");

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    //localStorage.removeItem("refreshToken");
    alert("Logged out");
    navigate('/Login');
  
  };
  
  return (
   <>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="position-sticky pt-3">
        <ul class="nav flex-column">
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to="/SchoolDetails">
              <span data-feather="home"></span>
              School Details
            </Link>
          </li>
        { selectrole==1?
         <>
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to="/CityMaster">
              <span data-feather="home"></span>
              Master City
            </Link>
          </li>

          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to="/LocalityMaster">
              <span data-feather="home"></span>
              Master Locality
            </Link>
          </li>

          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to="/SchoolEnquiry">
              <span data-feather="home"></span>
              School Enquiry
            </Link>
          </li>
          </>: ''}
         
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page"  onClick={handleLogout} to="/Login">
              <span data-feather="home"></span>
              Logout
            </Link>
          </li>
          
        </ul>

        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          {/* <span>Saved reports</span>
          <a class="link-secondary" href="#" aria-label="Add a new report">
            <span data-feather="plus-circle"></span>
          </a> */}
        </h6>
        {/* <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text"></span>
              Current month
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text"></span>
              Last quarter
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text"></span>
              Social engagement
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text"></span>
              Year-end sale
            </a>
          </li>
        </ul> */}
      </div>
    </nav>
    </>
  )
}