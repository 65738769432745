import React from 'react'


function ContactSection() {

  return (
     <>
     <div className="card mb-3">
          <h5 className="card-header callus_bg">Contact Us</h5>
          <div className="card-body cardbody_bg">
            <p className="pb-1 mb-0"><b>Contact:</b> +91-6291193256</p>
            <p className="pb-1 mb-0"><b>E-Mail ID:</b> info@theschoolroot.com</p>
            <p className="pb-3 mb-0"><b>Website:</b> www.theschoolroot.com</p>
          </div>
        </div>
      </>
  )
}

export default ContactSection