import React,{useEffect, useState} from 'react';
import { useNavigate  } from 'react-router-dom';

 function Protected(props) 
 {
    let Cmp=props.Cmp;

  const navigate = useNavigate();
 
  //const [visitorn,setvisitorn]= useState([]);
  useEffect(()=>{
    document.title="School Details";
    const auth=localStorage.getItem("accessToken");
    //auth?  navigate('/Post'): navigate('/Login');
    if(!auth){
     navigate('/Login'); 
    }},[]);
    

  return (
    <div>
        <Cmp />
    </div>
  )
}

export default Protected;