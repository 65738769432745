import { useState } from 'react';
import Logonew from "../Assets/School_eduation_logo.jpg";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const [open, setOpen] = useState(false);
  const [vcity, setCity] = useState('');
  const navigation=useNavigate();
  const handleCity = (e) => {
    e.preventDefault();
    setCity(e.target.value);
    console.log(vcity);
    navigation(`/explore-top-schools?cityname=${e.target.value}`);
  }

  return (
    <div>
      <div className="container-flud bgtop-red">
        <div className="container">
          <div className="row text-center">
            <p>Mobile: 6291193256 || Email: info@theschoolroot.com</p>
          </div>
        </div>

      </div>
      <nav class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    
  <Link class="navbar-brand" to="/">
    <img
                    alt="SchoolRoot" width="150" height="100%"
                    src={Logonew}
                    
                  /></Link>
                  <select class="form-select selectcity" onChange={handleCity}>
  <option selected>Select City</option>
  <option value="Kolkata">Kolkata</option>
  <option value="Delhi">Delhi</option>
  <option value="Mumbai">Mumbai</option>
</select>
                  
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/about-us">About Us</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/explore-top-schools?cityname=Kolkata">Explore Top Schools</Link>
        </li>

        <li class="nav-item">
          <Link class="nav-link" to="/admission-enquiry">Admission Enquiry</Link>
        </li>

        <li class="nav-item">
          <Link class="nav-link" to="/contact-us">Contact Us</Link>
        </li>
        
      </ul>
    </div>
  </div>
</nav>

<div className="container-flud bgtop">
        <div className="container">
          <div className="row">
           
          <div className="col-md-10  align-items-center d-flex text-right">
          Get 50% Discount on Admission, for Top Day-Cares, Pre-Schools, Play-Schools, Kids Schools and Montessori Schools in Kolkata</div>
          <div className="col-md-2 align-items-center d-flex"><Link to="/admission-enquiry" className="btn btn-secondary">New Admission</Link>
          </div>
          
           
          </div>
        </div>
</div>

    </div>
  )
}
