import { Button, Grid, Typography } from '@mui/material'
import React from 'react';
import EnquirySection from '../RightSidebar/EnquirySection';
import Enquirylogo from '../Assets/inquiry-form-image-800x870.jpg';

const CommonEnquirySection = ({data}) => {
  // const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
    <div className="container p-4">   
     <div className="row pb-5">  

     <div className="col-sm-6 col-md-6">
      <img src={Enquirylogo} className="img-fluid"/>
     </div>
      
     <div className="col-sm-6 col-md-6">
       <EnquirySection />
     </div>
     </div>
     </div>
    </>
  )
}

export default CommonEnquirySection