import { useState,useEffect } from 'react'
import EnquirySection from '../RightSidebar/EnquirySection';
import ContactSection from '../RightSidebar/ContactSection';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {page} from '../../Data/pages';
import SeoSectionPage from '../SchoolView/SeoSectionPage';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import GetAdmissionknowSection from '../../Components/CommonSection/GetAdmissionknowSection';
import PopularLocalitiesSection from '../../Components/CommonSection/PopularLocalitiesSection';
import CommonEnquirySection from '../../Components/CommonSection/CommonEnquirySection';
import AdmissionEnquirySection from '../../Components/CommonSection/AdmissionEnquirySection';
import CommonSchoolSection from '../../Components/CommonSection/CommonSchoolSection';
import Banner from '../Assets/Banner.jpg';
import GridSectionStatic from '../../Components/SchoolView/GridSectionStatic';



export default function WestBengalSection() {
 
  var sectionStyle = {
    backgroundImage: `url(${Banner})`
  }

  const { pageslug } = useParams();
  const [vgetPage,setPage]= useState({});
  console.log(pageslug);
  

    

useEffect(()=>{
  window.scrollTo(0, 0);
  const pagedata = page.filter((item) => item.seourl ==pageslug);
  console.log(pagedata);
  setPage(pagedata);
  
  
  
  //document.title="School in West Bengel - School Root Education";
  
 
},[pageslug]);

  return (
    <div className="bg-white">
       { vgetPage.length > 0?vgetPage.map((data,i)=>(<SeoSectionPage data={data}/>)):'null'}
      <Header/>
      <div>

  <div className="container-fluid" style={sectionStyle}>  
  <div className="container">    
  <div className="mt-10 pt-5 pb-5 text-white shadow mb-5 row">
  { vgetPage.length>0 && vgetPage.map((data,i)=>(
    <h1 className="my-4 py-3">{data.title}</h1> 
  ))}
  </div>
</div>
</div>
     
      
  
    <div className="container p-6 mb-4">
      <div className="row">
        <div className="col-md-8 col-sm-8">
          <div class="row">
        {vgetPage.length > 0 ? vgetPage.map((datavn,i)=>( <GridSectionStatic datan={datavn} />)) : null}
        </div>
        <div className="card cardbody_bg p-4">
        { vgetPage.length>0 && vgetPage.map((data,i)=>(
           <div>
           <div dangerouslySetInnerHTML={{ __html: data.description }} />
           </div>
        ))}
        </div>
         </div>
        <div className="col-md-4 col-sm-4">
        
         <ContactSection />
         <EnquirySection />
         

        </div>
      </div>
      </div>
       
      
         </div>

         <GetAdmissionknowSection />
     <PopularLocalitiesSection/>
     <CommonEnquirySection/>
     <AdmissionEnquirySection/>
     <CommonSchoolSection/>
          
      <Footer/>
    </div>


  )
}
