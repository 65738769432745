import { Button, Grid, Typography } from '@mui/material'
import React from 'react';
import {Link} from 'react-router-dom';

const FooterLink = ({data}) => {
  const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
     <li class="nav-item mb-1"><Link to={SCHOOLURL} class="nav-link p-0 text-muted">{data.title}</Link></li>
    </>
  )
}

export default FooterLink