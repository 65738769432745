export const homeSlidersdata=[
    {
        "image":"https://template.hasthemes.com/hurst-v1/hurst/img/slider/slider-2/1.jpg",
        "path":""
    },
    {
        "image":"https://template.hasthemes.com/hurst-v1/hurst/img/slider/slider-2/2.jpg",
        "path":""
    },
    {
        "image":"https://template.hasthemes.com/hurst-v1/hurst/img/slider/slider-2/2.jpg",
        "path":""
    },
    {
        "image":"https://template.hasthemes.com/hurst-v1/hurst/img/slider/slider-2/1.jpg",
        "path":""
    }
];