import React, { useState,useEffect } from 'react';
import LogoImage from '../Assets/banner-webp-2.e050ec0.webp';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { useParams,createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import GridSection from '../../Components/SchoolView/GridSection';
import GetAdmissionknowSection from '../../Components/CommonSection/GetAdmissionknowSection';
import PopularLocalitiesSection from '../../Components/CommonSection/PopularLocalitiesSection';
import CommonEnquirySection from '../../Components/CommonSection/CommonEnquirySection';
import AdmissionEnquirySection from '../../Components/CommonSection/AdmissionEnquirySection';
import CommonSchoolSection from '../../Components/CommonSection/CommonSchoolSection';
import FilterCities from '../../Components/CommonSection/FilterCities';

function SchoolView() {

  let [searchParams, setSearchParams] = useSearchParams();
  //let city = (new URLSearchParams(window.location.search)).get("selcity")
 //  const search = window.location.search;
   const params = new URLSearchParams();
  // console.log('test'+window.location);
  const city = searchParams.get('cityname');
  // const city = searchParams.get('selcity');
  const searchvale=searchParams.get('searchtext');
  //console.log('city'+city);//123

  // console.log(searchvale);
const LOCALITY_URL = `${process.env.REACT_APP_API_URL}auth/getLocalityData`;
const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getSchoolpublicdata`;
const [schooldata,setschooldata]= useState({});
const [localitydata,setlocalitydata]= useState({});
const [toCheck, setToCheck] = useState({});


var sectionStyle = {
  backgroundImage: `url(${LogoImage})`
}

const filterProducts = (value) =>
  setToCheck((prev) => {
    return { ...prev, [value]: !!!prev[value] };
  });
  //console.log('check'+JSON.stringify(toCheck));
  //return { ...prev, [value]: !!!prev[value] };



async function fetchDataLocality()
{
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cityname:city,
      })
    }
  const response=await fetch(LOCALITY_URL, requestOptions)
    .then((response) => response.json())
    .then((data) => {
       console.log(data);
       if(data.status==='Success')
       {
        setlocalitydata(data.data);
       }
       else
       {
        setlocalitydata();
       }
    })
    .catch(rejected => {
      console.log(rejected);
  });
    
}

async function fetchData()
{
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cityname:city,
        searchvalue:searchvale,
      })
    }
  const response=await fetch(VISITOR_URL, requestOptions)
    .then((response) => response.json())
    .then((data) => {
       console.log(data);
       if(data.status==='Success')
       {
        setschooldata(data.data);
       }
       else
       {
        setschooldata({});
       }
    })
    .catch(rejected => {
      console.log(rejected);
      setschooldata();
  });
    
}


useEffect(()=>{
  document.title="School Explore - School Root Education";
  fetchDataLocality();
  fetchData();
  
},[city]);





  return (
  <div>
      <Header/>
     
  {/* <div className="container-fluid setheaderbg">  
  <form method="GET" action="explore-top-schools">
  <div className="container">
      
  <div className="mt-10 mb-40 search-bg shadow row headerbottom p90">
    <h1 className="pb-2 searchtext">Search Best Schools Here</h1> 
    

    <div className="col-md-5">
    <select name="selcity" className="form-select">
      <option value="">Select City</option>
      <option value="Chakraborty Road">Chakraborty Road</option>
      <option value="Tollygunge">Tollygunge</option>
      <option value="Kestopur">Kestopur</option>
      <option value="Behala Charaktala">Behala Charaktala</option>
      <option value="Nayabad">Nayabad</option>
      <option value="New Alipore">New Alipore</option>
      <option value="NSC Bose Road">NSC Bose Road</option>
      <option value="Kasba">Kasba</option>
      <option value="Ramgarh">Ramgarh</option>
      <option value="Bhawanipur">Bhawanipur</option>
      <option value="Selimpur Road">Selimpur Road</option>
      <option value="Kavi Nazrul">Kavi Nazrul</option>
      <option value="Jawpur Road">Jawpur Road</option>
      <option value="Selimpur">Selimpur</option>
    </select>
  </div>
  <div class="col-md-5">
    <input type="text" className="form-control"  name="searchtext" placeholder="Enter Search Text" aria-label="Enter Search Text"/>
  </div>
  <div class="col-md-2">
  <button type="submit" name="submit" className="btn btn-secondary">Search</button>
  </div>
</div></div>
</form>
</div> */}


<div className="container-fluid">   
<div className="row mt-4 px-4">
 <div className="col-md-3">
  <div class="card cardbody_bg py-3 px-2">
  <h4>Filters</h4>
  <h4>Popular Area</h4>
 
  {localitydata.length > 0 ?localitydata.map((datan,i)=>(
    
    <div class="form-check" key={i}>
  <input class="form-check-input" type="checkbox" onChange={(e) => filterProducts(e.target.value)} value={datan.fld_locality_name} id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
   {datan.fld_locality_name}
  </label>
 </div>
    
   )) : 'Loading...'}
</div>

 </div>
 <div class="col-md-9">
  <div class="row">



 {schooldata.length > 0 ? schooldata.filter((datan) =>Object.keys(toCheck).length === 0 ? true : !!toCheck[datan.fld_localiy]
          )
          .map((datan,i)=>( <GridSection data={datan} />)) : 'Loading...'}         
 {/* {schooldata.length > 0 ? schooldata.map((datan,i)=>( <GridSection data={datan} />)) : 'Loading...'} */}
  </div></div>

</div>
</div>


<GetAdmissionknowSection />
     <PopularLocalitiesSection/>
     <CommonEnquirySection/>
     <AdmissionEnquirySection/>
     <CommonSchoolSection/>
     
     <Footer/>
    </div>
  );
}

export default SchoolView;
