import React from 'react'


function AddressSection() {

  return (
     <>
     <div className="card mb-3">
          <h5 className="card-header callus_bg">Address</h5>
          <div className="card-body cardbody_bg">

          <p className="pb-1 mb-0"><b>School Root Education</b></p>
          <p className="pb-1 mb-0">86 A, 2nd Floor, Topsia Road,</p>
          <p className="pb-1 mb-0">Topsia, Kolkata, West Bengal 700046, India</p>
          <p className="pb-1 mb-0"><b>Call -</b> +91-6291193256</p>
          <p className="pb-3 mb-0"><b>Email -</b>info@theschoolroot.com</p>
           
          </div>
        </div>
      </>
  )
}

export default AddressSection