import { useState,useEffect } from 'react'
import GetAdmissionknowSection from '../../Components/CommonSection/GetAdmissionknowSection';
import PopularLocalitiesSection from '../../Components/CommonSection/PopularLocalitiesSection';
import CommonEnquirySection from '../../Components/CommonSection/CommonEnquirySection';
import AdmissionEnquirySection from '../../Components/CommonSection/AdmissionEnquirySection';
import CommonSchoolSection from '../../Components/CommonSection/CommonSchoolSection';
import EnquirySection from '../RightSidebar/EnquirySection';
import ContactSection from '../RightSidebar/ContactSection';
import AddressSection from '../RightSidebar/AddressSection';
import Banner from '../Assets/Banner.jpg';
import { Helmet } from 'react-helmet';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';




export default function ProductDetails() {
 
  var sectionStyle = {
    backgroundImage: `url(${Banner})`
  }


useEffect(()=>{
  //document.title="School in West Bengel - School Root Education";
  
  window.scrollTo(0, 0);
},[]);

  return (
    <div className="bg-white">
       <Helmet>
        <title>About Us</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""/>
        <meta name="author" content="TheSchoolRoot" />
        <meta property="og:title" content="" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="" />
        <meta property="og:url" content=""/>
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:card" content="" />
       
       
       
      </Helmet>
      <Header/>
      <div>

      <div className="container-fluid" style={sectionStyle}>  
      <div className="container">
      
          <div className="mt-10 pt-5 pb-5 text-white shadow mb-5 row">
            <h1 className="my-4 py-3">About Us</h1> 
          </div>
     </div>
     </div>
     
    <div className="container p-6">
      <div className="row">
        <div className="col-md-8 col-sm-8 card cardbody_bg p-4">
          <p>The School Root Education is a technologically driven admissions platform that bridges the gap between admission-seeking parents and schools. We believe that parenting is the toughest journey in a person’s life, and so we have made it our mission to create a one-stop destination for all your parenting worries.</p>
          <p>Our quest is to guide parents in their decision of choosing the right school for their child as we understand the impact this decision can have on their family’s future. Parents aren’t the only group we are helping through our platform. We help schools boost their admissions by completely digitalizing and streamlining the process</p>
         </div>
        <div className="col-md-4 col-sm-4">
        
         <ContactSection />
         <AddressSection/>
         <EnquirySection />
         
         
        


        </div>
      </div>
      </div>
       
      
         </div>

         <GetAdmissionknowSection />
     <PopularLocalitiesSection/>
     <CommonEnquirySection/>
     <AdmissionEnquirySection/>
     <CommonSchoolSection/>
          
      <Footer/>
    </div>


  )
}
