import { useState,useEffect } from 'react';
import GetAdmissionknowSection from '../../Components/CommonSection/GetAdmissionknowSection';
import PopularLocalitiesSection from '../../Components/CommonSection/PopularLocalitiesSection';
import CommonEnquirySection from '../../Components/CommonSection/CommonEnquirySection';
import AdmissionEnquirySection from '../../Components/CommonSection/AdmissionEnquirySection';
import CommonSchoolSection from '../../Components/CommonSection/CommonSchoolSection';
import EnquirySection from '../RightSidebar/EnquirySection';
import ContactSection from '../RightSidebar/ContactSection';
import { Helmet } from 'react-helmet';
import AddressSection from '../RightSidebar/AddressSection';
import Header from '../../Components/Header/Header';
import Banner from '../Assets/Banner.jpg';
import Footer from '../../Components/Footer/Footer';




export default function AdmissionEnquiry() {
 
  var sectionStyle = {
    backgroundImage: `url(${Banner})`
  }

useEffect(()=>{
  //document.title="School in West Bengel - School Root Education";
  window.scrollTo(0, 0);
 
},[]);

  return (
    <div className="bg-white">
       <Helmet>
        <title>Admission Enquiry</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""/>
        <meta name="author" content="SchoolRoot Eduaction" />
        <meta property="og:title" content="" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="" />
        <meta property="og:url" content=""/>
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:card" content="" />
       
       
       
      </Helmet>
      <Header/>
      <div>

      <div className="container-fluid" style={sectionStyle}>  
      <div className="container">
      
  <div className="mt-10 pt-5 pb-5 text-white shadow mb-5 row">
    <h1 className="my-4 py-3">Admission Enquiry</h1> 



  </div>
</div>
     </div>
     
      
  
    <div className="container p-6">
      <div className="row">
        <div className="col-md-6 col-sm-6 card mb-5">
        <EnquirySection />
         </div>
        <div className="col-md-6 col-sm-6">
        
         <ContactSection />
         <AddressSection />
        </div>
      </div>
      </div>
        </div>


        <GetAdmissionknowSection />
     <PopularLocalitiesSection/>
     <CommonEnquirySection/>
     <AdmissionEnquirySection/>
     <CommonSchoolSection/>
          
      <Footer/>
    </div>


  )
}
