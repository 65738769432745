import React from 'react'
import { Link } from 'react-router-dom';

function GridSection({data}) {

  //console.log('test'+JSON.stringify(data));

  //const IMAGEURL= `https://www.theschoolroot.com/api/uploads/schoolimages/${data.fld_school_banner}`;
  const IMAGEURL= `${process.env.REACT_APP_IMAGE_URL}${data.fld_school_banner}`;
  const SCHOOLURL=`/school/${data.fld_url}`;
  //const newImageurl=`${IMAGEURL+product.fld_school_banner}`;
  return (
    <>
    {/* { data.map((data,i)=>(  */}

    <div class="col-md-4 mb-4" key={data.fld_id}>
    <div class="card">
    <img src={IMAGEURL} class="card-img-top" alt={data.fld_school_name}/>
    <div class="card-body cardbody_bg">
      <h5 class="card-title">{data.fld_school_name}</h5>
      <p class="card-text">{data.fld_address},{data.fld_city},{data.fld_state}-{data.fld_pincode}</p>
      <Link to={SCHOOLURL} class="btn btn-primary">View</Link>
    </div>
  </div>
  </div>
  
     {/* ))}  */}
    </>
  )
}

export default GridSection