import React, { useState,useEffect } from 'react';
import HomeSectionCarousel from '../HomeSectionCarousel/HomeSectionCarousel';
import HomeSectionCart from '../HomeSectionCart/HomeSectionCart';
import Slider   from '../Slider/Slider';
//import { product } from '../../Data/product';
import EnquirySection from '../RightSidebar/EnquirySection';
import LogoImage from '../Assets/banner-webp-2.e050ec0.webp';
import Header from '../../Components/Header/Header';
import {page} from '../../Data/pages';
import FooterLink from '../../Components/Footer/FooterLink';
import GetAdmissionknowSection from '../../Components/CommonSection/GetAdmissionknowSection';
import PopularLocalitiesSection from '../../Components/CommonSection/PopularLocalitiesSection';
import CommonEnquirySection from '../../Components/CommonSection/CommonEnquirySection';
import AdmissionEnquirySection from '../../Components/CommonSection/AdmissionEnquirySection';
import CommonSchoolSection from '../../Components/CommonSection/CommonSchoolSection';

import Footer from '../../Components/Footer/Footer';

function Home() {

const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getSchoolpublicdata`;
const [schooln,setschooln]= useState([]);
var sectionStyle = {
  backgroundImage: `url(${LogoImage})`
}


async function fetchData()
{

  const requestOptions = {
    method: "POST" };

  const response=await fetch(VISITOR_URL, requestOptions)
    .then((response) => response.json())
    .then((data) => {
       console.log(data);
       if(data.status==='Success')
       {
        setschooln(data.data);
     
       }
       else
       {
        setschooln();
       }
    })
    .catch(rejected => {
      console.log(rejected);
  });
    
}


useEffect(()=>{
  document.title="School in West Bengel - School Root Education";
  fetchData();
  
},[]);


  return (
    <div>
      <Header/>
      {/* <Slider /> */}



      <div className="container-fluid setheaderbg searchmargin_bottom">  
      <form method="GET" action="explore-top-schools">
      <div className="container">
      
  <div className="mt-10 search-bg shadow mb-5  row headerbottom p90">
    <h1 className="pb-2 searchtext">Search Best Schools Here</h1> 
    
   
    <div className="col-md-3 mb-md-3">
    {/* <input type="text" className="form-control" placeholder="Enter City Name" aria-label="Enter City Name"/>
    */}
    <select name="cityname" className="form-select">
      <option value="">Select City</option>
      <option value="Kolkata">Kolkata</option>
      <option value="Delhi">Delhi</option>
      <option value="Mumbai">Mumbai</option>
      {/* <option value="Chakraborty Road">Chakraborty Road</option>
      <option value="Tollygunge">Tollygunge</option>
      <option value="Kestopur">Kestopur</option>
      <option value="Behala Charaktala">Behala Charaktala</option>
      <option value="Nayabad">Nayabad</option>
      <option value="New Alipore">New Alipore</option>
      <option value="NSC Bose Road">NSC Bose Road</option>
      <option value="Kasba">Kasba</option>
      <option value="Ramgarh">Ramgarh</option>
      <option value="Bhawanipur">Bhawanipur</option>
      <option value="Selimpur Road">Selimpur Road</option>
      <option value="Kavi Nazrul">Kavi Nazrul</option>
      <option value="Jawpur Road">Jawpur Road</option>
      <option value="Selimpur">Selimpur</option> */}
    </select>
  </div>
  <div className="col-md-7">
    <input type="text" className="form-control" name="searchtext" placeholder="Enter School Name or Board or Address or Postal Code" aria-label="Enter Search Text"/>
  </div>
  <div className="col-md-2">
  <button type="submit" name="submit" className="btn btn-secondary">Search</button>
  </div>
  


  </div>
</div>
</form>
     </div>
        


     <div className="container">   
     <div className="row">

    { schooln.length > 0 ?  schooln.slice(0,9).map((item)=><HomeSectionCart  product={item}/>): null }
     
     </div>
     </div>


     <GetAdmissionknowSection />
     <PopularLocalitiesSection/>
     <CommonEnquirySection/>
     <AdmissionEnquirySection/>
     <CommonSchoolSection/>
     <Footer/>


    </div>
  );
}

export default Home;
