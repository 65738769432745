import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Header from '../../Admin/Header/Header';
import React, { useState, useRef, useMemo } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import LeftNav from '../../Admin/LeftNav/LeftNav';

export default function Post() {

 
	const editor = useRef(null);
	const [content, setContent] = useState('');

  const createUserApi = `${process.env.REACT_APP_API_URL}auth/saveSchoolData`;
  const getMasterApi = `${process.env.REACT_APP_API_URL}auth/getSchoolMasterData`;
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const [user, setUser] = useState({
    schoolname: "",
schoolshortname: "",
school_estd_year: "",
schoolclasses: "",
schoolboard: "",
language: "",
schoollogoimage:"",
schoolbannerimage:"",
schoolwebsite: "",
schoolcontactno: "",
schoolemail: "",
schoolmedium: "",
schoolacademicsession: "",
studentteacherratio: "",
metatitle: "",
metakeywords: "",
metadescription: "",
street_address: "",
city: "",
locality:"",
region: "",
postal_code: ""
});

const handelInput = (event) => {
 // event.preventDefault();
  const { name, value } = event.target;
  console.log(name, value)
  setUser({ ...user, [name]: value });
}


const handleSubmit = (e) => {
  e.preventDefault();
  // if(!validateForm())
      setIsClicked(true);
      const requestOptions = {
        method: "POST",
       // user.append('content',content);
       
       
        headers: {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem("accessToken")
                  },
        body: JSON.stringify(user)
        
      };

      fetch(createUserApi,requestOptions)
      .then((response) => response.json())
       .then((data) => {
           console.log(data);
           if(data.status=='Success')
           {
            setIsClicked(false);
            alert("School Register Successfully");
            navigate('/SchoolDetails');
             setUser({schoolname: "",
            schoolshortname: "",
            school_estd_year: "",
            schoolclasses: "",
            schoolboard: "",
            language: "",
            schoolwebsite: "",
            schoolcontactno: "",
            schoolemail: "",
            schoolmedium: "",
            schoolacademicsession: "",
            studentteacherratio: "",
            metatitle: "",
            metakeywords: "",
            metadescription: "",
            street_address: "",
            city: "",
            locality:"",
            region: "",
            content:"",
            postal_code: ""});
           }
           else
           {
            console.log(data.message);
           }
        });
}




  return (
    <>
    <Header/>
    <div class="container-fluid">
    <div class="row">
    <LeftNav/>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Add New School</h1>
        {content}
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            {/* <button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" class="btn btn-sm btn-outline-secondary">Export</button> */}
            <Link to="/SchoolDetails" class="btn btn-sm btn-outline-secondary">Back</Link>
          </div>
          {/* <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button> */}
        </div>
      </div>
     
      <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="row">
          <h2>Profile</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            This information will be displayed publicly so be careful what you share.
          </p>

          <div className="col-md-4">
              <label hmlFor="schoolname" className="form-label">School Name </label>    
                  <input
                    id="schoolname"
                    name="schoolname"
                    type="text"
                    placeholder="School Name"
                    value={user.schoolname} 
                    onChange={handelInput}
                    autoComplete="schoolname"
                    className="form-control"
                  />
            </div>
            

            <div className="col-md-4">
              <label htmlFor="schoolshortname" className="form-label">
                School Short Name:
              </label>
                  
                  <input
                    id="schoolshortname"
                    name="schoolshortname"
                    type="text"
                    placeholder="School Short Name"
                    value={user.schoolshortname} 
                    onChange={handelInput}
                    autoComplete="schoolshortname"
                    className="form-control"
                  />
            </div>

            <div className="col-md-4">
              <label htmlFor="school_estd_year" className="form-label">
                School Estd Year:
              </label>
                 
                  <input
                    id="school_estd_year"
                    name="school_estd_year"
                    type="text"
                    placeholder="School Estd Year"
                    value={user.school_estd_year} 
                    onChange={handelInput}
                    autoComplete="school_estd_year"
                    className="form-control"
                  />
                </div>
              



            <div className="col-md-4">
              <label htmlFor="schoolclasses" className="form-label">
                School Classes:
              </label>
                  
                  <input
                    id="schoolclasses"
                    name="schoolclasses"
                    type="text"
                    placeholder="School Classes"
                    value={user.schoolclasses} 
                    onChange={handelInput}
                    autoComplete="schoolclasses"
                    className="form-control"
                  />
                </div>


            <div className="col-md-4">
              <label htmlFor="schoolboard" className="form-label">
                School Board:
              </label>
              <select
                  id="schoolboard"
                  name="schoolboard"
                  value={user.schoolboard} 
                  onChange={handelInput}
                  autoComplete="school_board"
                  className="form-select"
                >
                  <option>Select Board</option>
                  <option>CBSE</option>
                  <option>ICSE</option>
                  <option>PESB</option>
                  <option>NIOS</option>
                 
                </select>
              
            </div>


            <div className="col-md-4">
              <label htmlFor="language" className="form-label">
                School Language Taught:
              </label>
             
                
                  <select
                  id="language"
                  name="language"
                  autoComplete="language"
                  value={user.language} 
                  onChange={handelInput}
                  className="form-select"
                >
                  <option>Hindi</option>
                  <option>English</option>
                 
                </select>
                
              </div>
            

            <div className="col-md-4">
              <label htmlFor="schoolwebsite" className="form-label">
                School Website:
              </label>
                  <input
                    id="schoolwebsite"
                    name="schoolwebsite"
                    type="text"
                    placeholder="School Website"
                    value={user.schoolwebsite} 
                    onChange={handelInput}
                    autoComplete="schoolwebsite"
                    className="form-control"
                  />
                </div>
             

            <div className="col-md-4">
              <label htmlFor="schoolcontactno" className="form-label"> School Contact No: </label>
                 
                  <input
                    id="schoolcontactno"
                    name="schoolcontactno"
                    type="text"
                    placeholder="School Contact No"
                    value={user.schoolcontactno} 
                    onChange={handelInput}
                    autoComplete="schoolcontactno"
                    className="form-control"
                  />
                </div>
             

            <div className="col-md-4">
              <label htmlFor="schoolemail" className="col-md-4">
                School Email:
              </label>
               
                  <input
                    id="schoolemail"
                    name="schoolemail"
                    type="text"
                    placeholder="School Email"
                    value={user.schoolemail} 
                    onChange={handelInput}
                    autoComplete="schoolemail"
                    className="form-control"
                  />
                </div>
             


            <div className="col-md-4">
              <label htmlFor="schoolmedium" className="form-label">
                School Medium:
              </label>
                 
                  <input
                    id="schoolmedium"
                    name="schoolmedium"
                    type="text"
                    placeholder="School Medium"
                    value={user.schoolmedium} 
                    onChange={handelInput}
                    autoComplete="schoolmedium"
                    className="form-control"
                  />
                </div>
             

            <div className="col-md-4">
              <label htmlFor="username" className="form-label">
                School Academic Session:
              </label>
                  <input
                    id="schoolacademicsession"
                    name="schoolacademicsession"
                    type="text"
                    placeholder="School Academic Session"
                    value={user.schoolacademicsession} 
                    onChange={handelInput}
                    autoComplete="schoolacademicsession"
                    className="form-control"
                  />
                </div>
             

            <div className="col-md-4">
              <label htmlFor="studentteacherratio" className="form-label">Student Teacher Ratio:</label>
                  <input
                    id="studentteacherratio"
                    name="studentteacherratio"
                    type="text"
                    placeholder="Student Teacher Ratio(15:1)"
                    value={user.studentteacherratio} 
                    onChange={handelInput}
                    autoComplete="studentteacherratio"
                    className="form-control"
                  />
                </div>
              



            <div className="col-md-4">
              <label htmlFor="metatitle" className="form-label">
              Meta Title:
              </label>
                
                  <input
                    id="metatitle"
                    name="metatitle"
                    type="text"
                    placeholder="Meta Title"
                    value={user.metatitle} 
                    onChange={handelInput}
                    autoComplete="metatitle"
                    className="form-control"
                  />
                </div>
             


            <div className="col-md-4">
              <label htmlFor="metakeywords" className="form-label">
             Meta Keywords:
              </label>
                
                  <input
                    id="metakeywords"
                    name="metakeywords"
                    type="text"
                    placeholder="Meta Keywords"
                    value={user.metakeywords} 
                    onChange={handelInput}
                    autoComplete="metakeywords"
                    className="form-control"
                  />
                </div>
              


            <div className="col-md-4">
              <label htmlFor="metadescription" className="form-label">
              Meta Description:
              </label>    
                  <input
                    id="metadescription"
                    name="metadescription"
                    type="text"
                    placeholder="Meta Description"
                    value={user.metadescription} 
                    onChange={handelInput}
                    autoComplete="metadescription"
                    className="form-control"
                  />
                </div>
              

            

            <div className="col-md-12">
              <label htmlFor="about" className="form-label">
                About
              </label>
             

              {/* value={content} onChange={newContent => setContent(newContent)} */}
              {/* <JoditEditor
			ref={editor}
			
      name="content"
      value={content} 
      onChange={newContent => setContent(newContent)}
		  className='form-control'
			
		/> */}
                <textarea
                  id="content"
                  name="content"
                  rows={3}
                  value={user.content} 
                  onChange={handelInput}
                  className="form-control"
                  defaultValue={''}
                />
              </div>
             

            
        
        
        <div className="row mt-4">
          <h2 className="text-base font-semibold leading-7 text-gray-900">School Location Details</h2>
         
          <div className="col-md-4">
              <label htmlFor="street-address" className="form-label">
                Street address
              </label>
              
                <input
                  id="street_address"
                  name="street_address"
                  type="text"
                  value={user.street_address} 
                  onChange={handelInput}
                  autoComplete="street_address"
                  className="form-control"
                />
              
            </div>

            <div className="col-md-4">
              <label htmlFor="city" className="form-label">
                City
              </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  value={user.city} 
                  onChange={handelInput}
                  autoComplete="address-level2"
                  className="form-control"
                />
            </div>

            <div className="col-md-4">
              <label htmlFor="locality" className="form-label">
               Locality
              </label>
              
                <input
                  id="locality"
                  name="locality"
                  type="text"
                  value={user.locality} 
                  onChange={handelInput}
                  autoComplete="locality"
                  className="form-control"
                />
            </div>

            <div className="col-md-4">
              <label htmlFor="region" className="form-label">
                State / Province
              </label>
             
              <select
                  id="region"
                  name="region"
                  autoComplete="region"
                  value={user.region} 
                  onChange={handelInput}
                  className="form-select"
                >
                   <option value=''>Select State</option>
                  <option value='ANDAMAN & NICOBAR ISLANDS'>ANDAMAN & NICOBAR ISLANDS</option>
                  <option value='ANDHRA PRADESH'>ANDHRA PRADESH</option>
                  <option value='ARUNACHAL PRADESH'>ARUNACHAL PRADESH</option>
                  <option value='ASSAM'>ASSAM</option>
                  <option value='BIHAR'>BIHAR</option>
                  <option value='CHANDIGARH'>CHANDIGARH</option>
                  <option value='CHHATTISGARH'>CHHATTISGARH</option>
                  <option value='DADRA & NAGAR HAVELI'>DADRA & NAGAR HAVELI</option>
                  <option value='DAMAN & DIU'>DAMAN & DIU</option>
                  <option value='GOA'>GOA</option>
                  <option value='GUJARAT'>GUJARAT</option>
                  <option value='HARYANA'>HARYANA</option>
                  <option value='HIMACHAL PRADESH'>HIMACHAL PRADESH</option>
                  <option value='JAMMU & KASHMIR'>JAMMU & KASHMIR</option>
                  <option value='JHARKHAND'>JHARKHAND</option>
                  <option value='KARNATAKA'>KARNATAKA</option>
                  <option value='KERALA'>KERALA</option>
                  <option value='LAKSHADWEEP'>LAKSHADWEEP</option>
                  <option value='MADHYA PRADESH'>MADHYA PRADESH</option>
                  <option value='MAHARASHTRA'>MAHARASHTRA</option>
                  <option value='MANIPUR'>MANIPUR</option>
                  <option value='MEGHALAYA'>MEGHALAYA</option>
                  <option value='MIZORAM'>MIZORAM</option>
                  <option value='NAGALAND'>NAGALAND</option>
                  <option value='NCT OF DELHI'>NCT OF DELHI</option>
                  <option value='ODISHA'>ODISHA</option>
                  <option value='PUDUCHERRY'>PUDUCHERRY</option>
                  <option value='PUNJAB'>PUNJAB</option>
                  <option value='RAJASTHAN'>RAJASTHAN</option>
                  <option value='SIKKIM'>SIKKIM</option>
                  <option value='TAMIL NADU'>TAMIL NADU</option>
                  <option value='TELANGANA'>TELANGANA</option>
                  <option value='TRIPURA'>TRIPURA</option>
                  <option value='UTTAR PRADESH'>UTTAR PRADESH</option>
                  <option value='UTTARAKHAND'>UTTARAKHAND</option>
                  <option value='WEST BENGAL'>WEST BENGAL</option>
                                    
                </select>
            </div>

            <div className="col-md-4">
              <label htmlFor="postal-code" className="form-label">
                ZIP / Postal code
              </label>
             
                <input
                  id="postal_code"
                  name="postal_code"
                  type="text"
                  maxlength="6"
                  minlength="6"
                  autoComplete="postal_code"
                  value={user.postal_code} 
                  onChange={handelInput}
                  className="form-control"
                />
              </div>

        </div>
        
      

      <div className="col-md-6 mt-8">
        <br/> <br/> 
        <button
          type="submit"  disabled={isClicked}
          className="btn bg-primary"
        >
          Save
        </button>
      </div>

      {isClicked?<>
                <div><h3 id="viewloading">Loading....</h3></div></>:''}
                <div id="msg" className="msg"></div>
                
    </div>
    </div>
    </form>

    </main>
    </div>
    </div>
   </>

  )
}

