import React, { useState,useEffect } from 'react';
import Logonew from "../Assets/School_eduation_logo.jpg";
import { useNavigate  } from 'react-router-dom';





export default function Login() {


   // const LOGIN_URL ="http://localhost/eventapi/index.php/auth/login"
   const LOGIN_URL = `${process.env.REACT_APP_API_URL}auth/login`;
   const [formErrors, setFormErrors] = useState({});
  //  const [isLoading, setIsLoading] = useState(false);
  // const [navigate, setNavigate] = useState(false);
    const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
 
   useEffect(()=>{
     document.title="Login  - The School Root Education";
   },[]);
   
 
   const validate=()=>{
     const errors=[];
     let isValid = true;
     const regx=/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/;
    if(!email)
    {
     errors.email='Email is required!';
     isValid = false;
    }
    else if(!regx.test(email))
    {
     errors.email='This is not valid Email format!';
     isValid = false;
    }
 
    if(!password)
    {
     errors.password='Password is required!';
     isValid = false;
    }
    setFormErrors(errors);
    return isValid;
 
   }
 
   const handleSubmit = (e) => {
     e.preventDefault();
    if(!validate())
    {
 
    }
    else{
     const requestOptions = {
       method: "POST",
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify({
         email: email,
         password: password,
       }),
     };
 
     fetch(LOGIN_URL, requestOptions)
       .then((response) => response.json())
       .then((data) => {
         setEmail("");
         setPassword("");
         //setNavigate(true)
 
          console.log(data);
          if(data.token!==null)
          {
 
         const access = data.token;
         const role = data.respdata['roletype'];
         localStorage.setItem('accessToken', access);
         localStorage.setItem('userrole', role);
         navigate('/SchoolDetails');
          }
          else
          {
           navigate('/Login');
          }
       });
     }
 
   };



  return (
    <>
      <div className='container'>
      <div className="card loginwidth">
         
          <h5 className="card-header">User Login</h5>
          <img src={Logonew} 
                 class="card-img-top"
                 alt="img"
                 width={200}/> 
          <div className="card-body">
          <form  onSubmit={handleSubmit}>
          <div className="mb-3">
          <label for="fullname" className="form-label">Email Id *</label>
          
          <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={email}  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
                {formErrors.email && <div className="error">{formErrors.email}</div>}
        </div>

        <div className="mb-3">
          <label for="emailaddress" className="form-label">Password *</label>
          
          <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  value={password}   onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                />
                {formErrors.password && <div className="error">{formErrors.password}</div>}

        </div>
           
        <div className="mb-3">
        <button type="submit" name="submit" className="btn btn-primary">Login</button>
        </div>
        </form>

          </div>
        </div>
        </div>
    </>
  )
}