import { Button, Grid, Typography } from '@mui/material'
import React from 'react';
import {Link} from 'react-router-dom';


const AdmissionEnquirySection = ({data}) => {
  // const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
       <div className="container-fluid callus_bg p-4">
     <div className="container">   
     <div className="row"> 

      <div className="col-sm-8 col-md-8">
      <p className="pt-2 mt-0 mb-0 pb-1">Start Smart with 50% Off New Admissions</p>
       <h2 className="pt-0 pb-1 mb-0">Leading Day-Cares, Pre-Schools, and Play-Schools</h2>
       <p className="pt-0 mt-0">Qualified Teachers, Structured Curriculum and Easy way Learning</p>

       
     </div> 

     <div className="col-sm-4 col-md-4 d-flex align-items-center">
     <Link to="/admission-enquiry" className="btn btn-secondary">Admission Enquiry 50% Off</Link>
     </div>
     </div>
     </div>
     </div>
    </>
  )
}

export default AdmissionEnquirySection