import { Button, Grid, Typography } from '@mui/material'
import React from 'react';
import {Link} from 'react-router-dom';
import Enquirylogo from '../Assets/Call-to-Action.jpg';

const GetAdmissionknowSection = ({data}) => {
  // const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
     <div className="container callus_bg p-4">   
     <div className="row">  

     <div className="col-sm-4 col-md-4 py-5">
      <img src={Enquirylogo} className="img-fluid"/>
     </div>
      
     <div className="col-sm-8 col-md-8 py-5">
       <p>Flexibility for Working Parents, Play-Based Learning, and Safety and Hygiene</p>
       <h2>Unlock 50% Off New Admissions for Montessori, Kindergarten, Preschools, Playhouses, Kids Schools, and Day Cares!</h2>
       <p>Safe Environment, Age-Appropriate Activities, and Professional Staff</p>

       <Link to="/admission-enquiry" className="btn btn-secondary">Get Admission Now</Link>
     </div>
     </div>
     </div>
    </>
  )
}

export default GetAdmissionknowSection