import { Button, Grid, Typography } from '@mui/material'
import React from 'react';
import {page} from '../../Data/pages';
import {Link} from 'react-router-dom';
import PopularLocalitiesDataSection from '../../Components/CommonSection/PopularLocalitiesDataSection';


const PopularLocalitiesSection = ({data}) => {
  // const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
     <div className="container p-4">   
     <div className="row popullar-link">  
      <div className="col-md-12 text-center">
      <h1>Popular Localities in Kolkata</h1>
      <p className="pb-3">Find your own choice of day-care, pre-school, play-school, and school, for your child to offer him a good start.</p>
      </div>

     
     {/* <div className="col-sm-4 col-md-4 mb-4">
      <Link to="/west-bengal/kids-schools-in-chakraborty-road">
      <div className="setheaderbg p-4 shadow  rounded">
      Chakraborty Road
      </div>
      </Link>
     </div> */}


     {page.length > 0 && page.slice(56,68).map((data,i)=>(
       <PopularLocalitiesDataSection data={data}/>
     ))}

{/* 
     <div className="col-sm-4 col-md-4 card mb-4">
     <Link href="/west-bengal/kids-schools-in-tollygunge">
     <div className="setheaderbg p-4 shadow rounded">
      Tollygunge
      </div>
      </Link>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <Link href="/west-bengal/kids-schools-in-kestopur">
     <div className="setheaderbg p-4 shadow rounded">
     Kestopur
      </div>
      </Link>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <Link href="/west-bengal/kids-schools-in-behala-charaktala">
     <div className="setheaderbg p-4 shadow rounded">
     Behala Charaktala
      </div>
      </Link>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-nayabad">
     <div className="setheaderbg p-4 shadow rounded">
     Nayabad
      </div>
      </a>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-new-alipore">
     <div className="setheaderbg p-4 shadow  rounded">
     New Alipore
      </div>
      </a>
     </div>

     
     <div className="col-sm-4 col-md-4 mb-4">
     <a href="/west-bengal/kids-schools-in-kasba">
      <div className="setheaderbg p-4 shadow  rounded">
      Kasba
      </div>
      </a>
     </div>


     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-nsc-bose-road">
     <div className="setheaderbg p-4 shadow rounded">
     NSC Bose Road
      </div>
      </a>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-ramgarh">
     <div className="setheaderbg p-4 shadow rounded">
     Ramgarh
      </div>
      </a>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-bhawanipur">
     <div className="setheaderbg p-4 shadow rounded">
     Bhawanipur
      </div>
      </a>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-selimpur-road">
     <div className="setheaderbg p-4 shadow rounded">
     Selimpur Road
      </div>
      </a>
     </div>

     <div className="col-sm-4 col-md-4 card mb-4">
     <a href="/west-bengal/kids-schools-in-kavi-nazrul">
     <div className="setheaderbg p-4 shadow  rounded">
     Kavi Nazrul
      </div>
      </a>
     </div> */}
      <div className="col-md-4"><Link to="/explore-top-schools" className="btn btn-primary">Find More</Link></div>
     </div>
     </div>
    </>
  )
}

export default PopularLocalitiesSection