import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <div className="container-fluid bgtop">
     


<div class="container">
  <footer>

    <div class="d-flex justify-content-between pt-2">
      <p> 2024-2025 The Schoot Root Education. All rights reserved.</p>
      {/* <ul class="list-unstyled d-flex">
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#twitter"/></svg></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#instagram"/></svg></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#facebook"/></svg></a></li>
      </ul> */}
    </div>
  </footer>
</div>
       </div>
  )
}

export default Footer