import React from 'react'
import { Link } from 'react-router-dom';







function HomeSectionCart({product}) {

  //const IMAGEURL= `https://www.theschoolroot.com/api/uploads/schoolimages/${product.fld_school_banner}`;
  const IMAGEURL= `${process.env.REACT_APP_IMAGE_URL}${product.fld_school_banner}`;
  const SCHOOLURL=`/school/${product.fld_url}`;
  //const newImageurl=`${IMAGEURL+product.fld_school_banner}`;
  return (
    <div class="col-md-4 mb-4" key={product.fld_id}>
    <div class="card">
    <img src={IMAGEURL} class="card-img-top" alt={product.fld_school_name}/>
    <div class="card-body cardbody_bg">
      <h5 class="card-title">{product.fld_school_name}</h5>
      <p class="card-text">{product.fld_address},{product.fld_city},{product.fld_state}-{product.fld_pincode}</p>
      <Link to={SCHOOLURL} class="btn btn-primary">View</Link>
    </div>
  </div>
  </div>
  )
}

export default HomeSectionCart