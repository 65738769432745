import React from 'react'



function SingleViewSection({data}) {

 // const IMAGEURL= `https://theschoolroot.com/api/uploads/schoolimages/${data.fld_school_banner}`;
 const IMAGEURL= `${process.env.REACT_APP_IMAGE_URL}${data.fld_school_banner}`;
  const SCHOOLURL=`/school/${data.fld_url}`;
  //document.getElementById('schoolcontent').innerHTML=data.fld_school_about_us;
  //const newImageurl=`${IMAGEURL+product.fld_school_banner}`;
  return (
     <>
      <div class="col-md-12 col-sm-12 card  mb-4">
        <h5 class="card-header callus_bg">Quick Facts </h5>
        <div className="card-body cardbody_bg">
          <div className="row pb-3">
        <div className='col-md-4 col-sm-4'><b>Board</b><br/>{data.fld_school_board}</div>
       <div className='col-md-4 col-sm-4'><b>Gender</b><br/>Coed</div>
       <div className='col-md-4 col-sm-4'><b>Classes</b><br/>{data.fld_school_classes}</div>

       <div className='col-md-4 col-sm-4'><b>Academic Session</b><br/>{data.fld_academic_session}</div>
       <div className='col-md-4 col-sm-4'><b>Medium</b><br/>{data.fld_medium}</div>
       <div className='col-md-4 col-sm-4'><b>Student Teacher Ratio</b><br/>{data.fld_student_teacher_ratio}</div>
       </div>
      </div>
      </div>
      

      <div class="col-md-12 col-sm-12 card mb-4">
      <h5 class="card-header callus_bg">About the School </h5>
      <div class="card-body cardbody_bg">
        {/* <div id="schoolcontent">{data.fld_school_about_us}</div> */}
        <div dangerouslySetInnerHTML={{ __html: data.fld_school_about_us }} />
      {/* {data.fld_school_about_us} */}
      </div>
      </div>
      </>
  )
}

export default SingleViewSection