import React , { useState,useEffect } from 'react'
import { useNavigate  } from 'react-router-dom';

 const createUserApi = `${process.env.REACT_APP_API_URL}auth/saveEnquiryData`;
 

function EnquirySection() {

  const navigate = useNavigate();
  const [user, setUser] = useState({
    fullname: "",
    emailaddress: "",
    phoneno: "",
    address: "",
    comment: ""
  });
  
  const handelInput = (event) => {
     event.preventDefault();
     const { name, value } = event.target;
     console.log(name, value)
     setUser({ ...user, [name]: value });
   }
  
  
   
  const handleSubmit = (e) => {
    e.preventDefault();
      
        const requestOptions = {
          method: "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(user)
        };
  
        fetch(createUserApi,requestOptions)
        .then((response) => response.json())
         .then((data) => {
             console.log(data);
             if(data.status=='Success')
             {
              alert("Thank you for Enquiry");
              navigate('/');
               setUser({ fullname: "",
                emailaddress: "",
                phoneno: "",
                address: "",
                comment: ""
              });
             }
             else
             {
              console.log(data.message);
             }
          });
  }
  
  return (
     <>
      <div className="card">
        <form onSubmit={handleSubmit}>
          <h5 className="card-header callus_bg">Quick Enquiry</h5>
          <div className="card-body cardbody_bg">
           
          <div className="mb-3">
          <label for="fullname" className="form-label">Full Name</label>
          <input type="text" className="form-control" name="fullname" id="fullname"  value={user.fullname} 
                    onChange={handelInput} placeholder="Shiva"/>
        </div>

        <div className="mb-3">
          <label for="emailaddress" className="form-label">Email address</label>
          <input type="email" className="form-control" id="emailaddress" name="emailaddress" value={user.emailaddress} 
                    onChange={handelInput} placeholder="info@theschoolroot.com"/>
        </div>

        <div className="mb-3">
          <label for="phoneno" className="form-label">Phone No:</label>
          <input type="text" className="form-control" id="phoneno" name="phoneno" value={user.phoneno} 
                    onChange={handelInput} placeholder="9191919191"/>
        </div>

        <div className="mb-3">
          <label for="address" className="form-label">Address</label>
          <input type="text" className="form-control" id="address" name="address" value={user.address} 
                    onChange={handelInput} placeholder="Enter Address"/>
        </div>

        <div className="mb-3">
          <label for="comment" className="form-label">Comments</label>
          <textarea className="form-control" id="comment" name="comment" value={user.comment} 
                    onChange={handelInput} rows="2"></textarea>
        </div>
           
        <div className="mb-3">
        <button type="submit" name="submit" className="btn btn-primary">Submit</button>
        </div>


          </div>
          </form>
        </div>
        
      </>
  )
}

export default EnquirySection