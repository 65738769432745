import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Logonew from "../../Assets/School_eduation_logo.jpg";




export default function Header() {

  const navigate = useNavigate();

const handleLogout = () => {
  localStorage.removeItem('accessToken');
  //localStorage.removeItem("refreshToken");
  alert("Logged out");
  navigate('/Login');

};

  
  return (
   <>
   <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"><img src={Logonew} width={120} /></a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  {/* <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"/> */}
  <div class="navbar-nav">
    <div class="nav-item text-nowrap">
      <a class="nav-link px-3"  onClick={handleLogout}>Sign out</a>
    </div>
  </div>
</header>


    </>
  )
}