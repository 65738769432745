import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import {page} from '../../Data/pages';
import {Link} from 'react-router-dom';
import FooterLink from '../../Components/Footer/FooterLink';

const CommonSchoolSection = () => {
 // const SCHOOLURL=`/west-bengal/${data.seourl}`;
  return (
    <>
     <div className="container-fluid setheaderbg">
  <div class="container">
  <footer class="py-5">
    <div class="row school-listing-section">
    <div class="col-md-3 mb-2">
        <h5 className="navsection-heading">Montessori Schools in Kolkata</h5>
        <ul class="nav flex-column">
     {page.length > 0 && page.slice(0,14).map((data,i)=>(
       <FooterLink data={data}/>
     ))}
     </ul>
     </div>

     <div class="col-md-3 mb-2">
        <h5 className="navsection-heading">Kindergarten Schools in Kolkata</h5>
        <ul class="nav flex-column">
     {page.length > 0 && page.slice(14,28).map((data,i)=>(
       <FooterLink data={data}/>
     ))}
     </ul>
     </div>

     <div class="col-md-3 mb-2">
        <h5 className="navsection-heading">Preschools in Kolkata</h5>
        <ul class="nav flex-column">
     {page.length > 0 && page.slice(28,42).map((data,i)=>(
       <FooterLink data={data}/>
     ))}
     </ul>
     </div>

     <div class="col-md-3 mb-2">
        <h5 className="navsection-heading">Playhouses in Kolkata</h5>
        <ul class="nav flex-column">
     {page.length > 0 && page.slice(42,56).map((data,i)=>(
       <FooterLink data={data}/>
     ))}
     </ul>
     </div>

     <div class="col-md-3">
        <h5 className="navsection-heading">Kids Schools in Kolkata</h5>
        <ul class="nav flex-column">
     {page.length > 0 && page.slice(56,70).map((data,i)=>(
       <FooterLink data={data}/>
     ))}
     </ul>
     </div>


     <div class="col-md-3">
        <h5 className="navsection-heading">Day Cares in Kolkata</h5>
        <ul class="nav flex-column">
     {page.length > 0 && page.slice(70,84).map((data,i)=>(
       <FooterLink data={data}/>
     ))}
     </ul>
     </div>

     <div class="col-md-3">
        <h5 className="navsection-heading">Important Links</h5>
        <ul className="nav flex-column">
        <li className="nav-item mb-1"><Link to="/" class="nav-link p-0 text-muted">Home</Link></li>
        <li className="nav-item mb-1"><Link to="/about-us" class="nav-link p-0 text-muted">About Us</Link></li>
        <li className="nav-item mb-1"><Link to="/post-query" class="nav-link p-0 text-muted">Post Query</Link></li>
        <li className="nav-item mb-1"><Link to="/privacy-policy" class="nav-link p-0 text-muted">Privacy Policy</Link></li>
        <li className="nav-item mb-1"><Link to="/contact-us" class="nav-link p-0 text-muted">Contact Us</Link></li>
        <li className="nav-item mb-1"><Link to="/sitemap" class="nav-link p-0 text-muted">Sitemap</Link></li>
     </ul>
     </div>

</div>
</footer>
</div>
</div>
    </>
  )
}

export default CommonSchoolSection